<template>
    <section>
        <b-card>
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <b-row>
                                <!-- Image -->
                                <b-col cols="12">
                                    <div id="preview">
                                        <img v-if="url" class="img-fluid" :src="url" width="350" alt="image" />
                                    </div>
                                    <b-form-group
                                        label-for="image">
                                        <template #label>
                                            Imagen <strong>(Formato: webp) (1280x1280)</strong>
                                        </template>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="imagen"
                                        >
                                            <b-form-file
                                                id="image"
                                                v-model="file"
                                                placeholder="Elija un archivo ..."
                                                :state="errors.length > 0 ? false : null"
                                                @change="onFileChange"
                                                accept=".webp"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">                
                                    <b-alert  variant="info" :show="true">
                                        <div class="alert-body">
                                            <ul class="mb-0">
                                                <li>
                                                    Comprime y optimiza tus imágenes en 
                                                    <b-link
                                                        class="alert-link"
                                                        href="https://tinypng.com/"
                                                        target="_blank"
                                                    >
                                                    Tinify
                                                    </b-link>
                                                </li>
                                                <li>
                                                    Convierte tus imágenes a .webp en 
                                                    <b-link
                                                        class="alert-link"
                                                        href="https://pixelied.com/convert/jpg-converter"
                                                        target="_blank"
                                                    >
                                                        Pixelied
                                                    </b-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-alert>
                                </b-col>
                                
                                <!-- Date -->
                                <b-col cols="12" md="2">
                                    <b-form-group
                                        label="Fecha de publicación"
                                        label-for="date">
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="fecha del blog"
                                        >
                                            <flat-pickr
                                                id="date"
                                                v-model="date"
                                                class="form-control"
                                                :config="config"
                                                placeholder="YYYY-MM-DD"
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
    
                                <!-- Product Type -->
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group
                                        label="Tipo(s) de producto"
                                        label-for="product-type"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="tipo de producto"
                                        >
                                            <v-select
                                                id="product-type"
                                                v-model="product_type"
                                                :options="product_typeOptions"
                                                multiple
                                                label="name"
                                                :selectable="() => product_type.length < 3"
                                                :state="errors.length > 0 ? false:null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>					
                                    </b-form-group>
                                </b-col>
                                
                                <!-- Tags -->
                                <b-col cols="12" md="5">
                                    <b-form-group
                                        label="Etiquetas"
                                        label-for="tags">
                                        <v-select
                                            id="tags"
                                            placeholder="Agregar Etiquetas"
                                            v-model="tags"
                                            multiple
                                            taggable
                                            push-tags
                                            label="title"
                                        />
                                    </b-form-group>
                                </b-col>
                                
                                <!-- Author -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Autor"
                                        label-for="author">
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="autor"
                                        >
                                            <b-form-input
                                                id="author"
                                                v-model="author"
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                    
                                <!-- Title -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Título"
                                        label-for="title">
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="título"
                                        >
                                            <b-form-input
                                                id="title"
                                                v-model="title"
                                                placeholder="Título del blog ..."
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
    
                                <!-- Short Description -->
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group
                                        label="Descripción (Resumen)"
                                        label-for="short-description"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="descripción"
                                        >
                                            <b-textarea
                                                id="short-description"
                                                v-model="shortDescription"
                                                :state="(errors.length > 0 ? false:null)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>					
                                    </b-form-group>
                                </b-col>
    
                                <!-- Long Description -->
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group
                                        label="Contenido"
                                        label-for="long-description"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="contenido"
                                        >
                                            <quill-editor
                                                v-model="longDescription"
                                                :options="snowOption"
                                                :state="(errors.length > 0 ? false:null)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>					
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!-- Submit Button -->
                        <b-col>
                            <b-button
                                variant="primary"
                                class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
                                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                                @click.prevent="validationForm"
                            >
                                AGREGAR
                            </b-button>
                        </b-col>

                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </section>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { quillEditor } from 'vue-quill-editor';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import Toast from 'vue-toastification';
    import vSelect from 'vue-select';
    import axios from 'axios';
    import Vue from 'vue';

    Vue.use(Toast);

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
           
            vSelect,
            flatPickr,
            quillEditor
        },
        directives: {
            Ripple
        },
        data() {
            return {
                required,
                
                author: null,
                title: null,
                file: null,
                url: null,
                longDescription: null,
                shortDescription: null,
                date: null,
                tags: [],
                product_typeOptions: [],
                product_type: [],
                snowOption: {
					theme: 'snow'
				},
                config: {
                    enableTime: false,
                    locale: Spanish
                }
            }
        },
        async beforeMount () {
            await this.getProductTypes();
        },
        methods: {
            onFileChange(e) {
                if (e.target.files.length) {
                    const file = e.target.files[0];
                    this.file = file;
                    this.url = URL.createObjectURL(file);
                } else {
                    this.file = null;
                    this.url = null;
                }
            },
            async getProductTypes(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/product-types`);
				this.product_typeOptions = response.data;
			},
            validationForm() {
                this.$refs.simpleRules.validate()
                    .then(success => {
                        if (success) {

                            const productTypeIds = this.product_type.length > 0 ? this.product_type.map(item => item._id) : [];

                            let formData = new FormData();

                            formData.append('author', this.author);
                            formData.append('title', this.title);
                            formData.append('file', this.file);
                            formData.append('longDescription', this.longDescription);
                            formData.append('shortDescription', this.shortDescription);
                            formData.append('product_type', productTypeIds);
                            formData.append('date', this.date);
                            formData.append('tags', this.tags);

                            axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/blog/create`, formData, {
                                    headers: { 'Content-Type': 'multipart/form-data'}
                                })
                                .then(response => {

                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });

                                   this.author = null;
                                   this.title = null;
                                   this.file = null;
                                   this.fileName = null;
                                   this.url = null;
                                   this.longDescription = null;
                                   this.shortDescription = null;
                                   this.date = null;
                                   this.tags = [];

                                   this.$refs['simpleRules'].reset();

                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al crear el blog.';
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });

                        }
                    });
            }
        }
    }

</script>

<style scoped>

    #preview {
        text-align: center;
    }

    #preview img {
        padding: .25rem;
        margin-bottom: 1.5rem;
        border-radius: .25rem;
        background-color: #343a40;
        border: 1px solid #dee2e6;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/quill.scss';
</style>